import { Permissions } from "../const";

const STORAGE_PERMISSION_KEY = "user-permissions";

export const setPermissionsTostorage = (permissions) => {
  sessionStorage.setItem(STORAGE_PERMISSION_KEY, JSON.stringify(permissions));
};

const getPermissionsFromStorage = () => {
  return JSON.parse(sessionStorage.getItem(STORAGE_PERMISSION_KEY) || "[]");
};

export const hasPermission = (permission: Permissions) => {
  const permissions = getPermissionsFromStorage();
  return permissions.includes(permission);
};
