import { queryOptions, useQuery } from "@tanstack/react-query";
import { useRequiredParams } from "~utils/url";
import { ApiResponse } from "~api/api-response.model";
import { useSuspenseQueryDeferred } from "~utils/query-hooks";
import { t } from "@lingui/macro";
import { orderBy } from "lodash-es";
import { assetsApiRequest } from "../api/api-request";
import { Alarm } from "./alarm.model";
import { AlarmTab } from "./alarm.utils";
import { PointType } from "../point/point.model";

export type AlarmCount = {
  all: number;
  active: number;
  unacknowledged: number;
};

type AlarmsResponse = {
  alarms: Alarm[];
  count: AlarmCount;
};

type AlarmListParams = {
  companyId: number;
  tab?: AlarmTab;
  search?: string;
  sort?: string;
  order?: string;
  page: number;
  pageSize: number;
  alarmGroups?: string[];
  lockStates?: string[];
  deviceId?: number;
  levelId?: number;
};

type AlarmDetailsParams = {
  companyId: number;
  deviceId: number;
  id: number;
};

type ExtraAlarmQueryOptions = Omit<typeof queryOptions, "queryKey" | "queryFn">;

export const alarmQueries = {
  list: (params: AlarmListParams, options?: ExtraAlarmQueryOptions) =>
    queryOptions({
      queryKey: ["alarms", params],
      queryFn: () => fetchAlarms(params),
      ...options,
    }),
  details: (params: AlarmDetailsParams) =>
    queryOptions({
      queryKey: ["alarm-details", params],
      queryFn: () => fetchAlarm(params),
    }),
};

// Queries

export function useAlarmsQuery(
  params: Omit<AlarmListParams, "companyId">,
  options?: ExtraAlarmQueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(alarmQueries.list({ companyId, ...params }, options));
}

export function useAlarmsSuspenseQuery(
  params: Omit<AlarmListParams, "companyId">,
  options?: ExtraAlarmQueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(
    alarmQueries.list({ companyId, ...params }, options),
  );
}

export function useAlarmSuspenseQuery(
  params: Omit<AlarmDetailsParams, "companyId">,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(
    alarmQueries.details({ companyId, ...params }),
  );
}

// Fetchers

async function fetchAlarms({
  companyId,
  order,
  search,
  sort,
  page,
  pageSize,
  alarmGroups,
  deviceId,
  levelId,
}: AlarmListParams) {
  // TEMPORARY: Can't sort with puck values in the backend yet, needs to be fixed in the future -Santeri
  const actualSort = sort?.startsWith("value") ? undefined : sort;
  // Replace NO GROUP with null for the query since its actual value in the DB is null. NO GROUP is just shown to the user
  const actualGroups = alarmGroups?.map((g) =>
    g === t`NO GROUP` ? "null" : g,
  );
  const alarms = await assetsApiRequest
    .get<
      ApiResponse<Alarm[]>
    >(`analytics/companies/${companyId}/points`, { params: { page, pageSize, sort: actualSort, order, search, alarmGroups: actualGroups, types: [PointType.Alarm], deviceId, levelId, nested: levelId ? true : undefined } })
    .then((resp) => resp.data.data.map((d) => new Alarm(d)));

  // TEMPORARY: Spread the "type" count of our alarms into separate object properties. We should make a separate count endpoint in the future -Santeri
  const count = alarms.reduce<AlarmCount>(
    (cur, next) => {
      return {
        all: cur.all + 1,
        active: next.isActive === true ? cur.active + 1 : cur.active,
        unacknowledged:
          next.isAcknowledged === false
            ? cur.unacknowledged + 1
            : cur.unacknowledged,
      };
    },
    { active: 0, all: 0, unacknowledged: 0 },
  );

  return {
    alarms,
    count,
  } as AlarmsResponse;
}

async function fetchAlarm(params: AlarmDetailsParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Alarm>
    >(`analytics/companies/${params.companyId}/devices/${params.deviceId}/points/${params.id}`)
    .then((resp) => new Alarm(resp.data.data));
}

// Utils

function filterAlarmByActiveStatus(alarm: Alarm, tab: AlarmTab) {
  switch (tab) {
    case "active":
      return alarm.isActive === true;
    case "unacknowledged":
      return alarm.isAcknowledged === false;
    default:
      return true;
  }
}

type AlarmFilter = Pick<
  AlarmListParams,
  "tab" | "page" | "pageSize" | "sort" | "order"
>;

export function filterAlarms(
  { tab, page, pageSize, sort, order }: AlarmFilter,
  alarms: Alarm[],
) {
  const tabData =
    tab != undefined
      ? alarms.filter((alarm) => filterAlarmByActiveStatus(alarm, tab))
      : alarms;

  // TEMPORARY: Sort data in the frontend when sorted by any puck value
  const sortedData =
    sort && sort.startsWith("value")
      ? orderBy(tabData, sort, order as "asc" | "desc")
      : tabData;

  return sortedData.slice(0, page * pageSize);
}
